<template>
  <div>
    <eden-loader v-if="loading"/>
    <template v-else>
    <el-row type="flex">
      <el-col :span="24">
        <!-- <p v-if="setCustomers.length < 10 || showAll">
          To:
          <el-tag
            v-for="(customer, index) in setCustomers"
            :key="index"
            class="mb-1"
          >
            {{ customer.name }}
            <i
              v-if="setCustomers.length > 1"
              class="el-icon-close"
              @click="removeCustomer(customer.id)"
            ></i>
          </el-tag>
        </p> -->
        <template v-if="!setAddRecipientStatus">
        <p>
          To:
          <el-tag
            v-for="(customer, index) in setCustomers.slice(0, 10)"
            :key="index"
            class="mb-1"
          >
            {{ customer.name }}
            <i class="el-icon-close" @click="removeCustomer(customer.id)"></i>
          </el-tag>
          <el-tag v-if="setCustomers.length >10" type="success" class="text-cursor" @click="showAll = false">
            +{{ setCustomers.length - 10 }} more
          </el-tag>
        </p>
        <div class="text-cursor text-primary font-sm text-right" @click="addMorePeople">
            <span class="el-icon-plus mr-1 faint-circle"></span>Add more
            recipients
        </div>
        </template>
        <template v-else>
          <p class="heightBox">
          To:
          <el-select
            class="styled-select"
            v-model="setCustomers"
            filterable
            multiple
            allow-create
            value-key="id"
          >
            <el-option
              v-for="(customer,index) in allCustomers"
              :key="index"
              :label="formatName(customer.name)"
              :value="customer"
            >
            </el-option>
          </el-select>
        </p>
        </template>
      </el-col>
    </el-row>
    </template>
  </div>
</template>

<script>
import gardener from "@/requests/gardeners/gardener"
import customer from "@/requests/customers/customer"
export default {
  name: "BulkRecipientList",
  props: {
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    addRecipient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: false,
      allCustomers:[],
      loading:false
    };
  },
  computed: {
    setCustomers: {
      get() {
        return this.customers;
      },
      set(value) {
        this.$emit("update:customers", value);
      },
    },
     setAddRecipientStatus: {
      get() {
        return this.addRecipient;
      },
      set(value) {
        this.$emit("update:addRecipient", value);
      },
    }
  },
  methods: {
    removeCustomer(id) {
      if(this.setCustomers.length>1){
        this.setCustomers = this.setCustomers.filter(
          (customer) => customer.id != id
        );
      }
      
    },
    addMorePeople(){
      this.loading = true
      customer
      .profile(this.setCustomers[0].id)
      .then(response=>{
        gardener
        .get(response.data.data.gardener_id)
        .then(response=>{
          this.allCustomers = response.data.data.customers
          this.setAddRecipientStatus = true
          this.loading = false
        })
      })
      
      
      
      
      
    }
  },
};
</script>

<style lang="scss" scoped>
.faint-circle {
  border: 1px solid var(--eden-grey-senary);
  padding: 2px 2px;
  background-color: var(--eden-grey-senary);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  font-size: 8px;
}
.heightBox{
  max-height: 30vh;
  overflow: auto;
}
</style>
