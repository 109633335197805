<template>
  <div>
    <p>
      <span>
        <span v-if="!setAddRecipientStatus" class="is-flex justify-between">
          <span>
            To:
            <el-tag class="mb-1">
              {{ customer.name }}
            </el-tag>
          </span>
          <span v-if="allCustomers.length" class="text-cursor text-primary font-sm" @click="addMorePeople">
            <span class="el-icon-plus mr-1 faint-circle"></span>Add more
            recipients
          </span>
        </span>
        <span v-else class="is-flex">
          <span style="height: 12px; line-height: 30px">To: &nbsp;</span>
          <el-select
            class="styled-select"
            v-model="selectedCustomers"
            filterable
            multiple
            allow-create
          >
            <el-option
              v-for="(customer, index) in allCustomers"
              :key="index"
              :label="formatName(customer.name)"
              :value="customer.id"
            >
            </el-option>
          </el-select>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "SingleRecipientList",
  props: {
    customer: {
      type: Object,
      default() {
        return null;
      },
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    allCustomers: {
      type: Array,
      default() {
        return [];
      },
    },
    addRecipient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: false,
      all: "",
      selectedCustomers: [],
    };
  },
  mounted() {
    if (this.customer != null) {
      this.selectedCustomers.push(this.customer.id);
    }
  },
  watch: {
    selectedCustomers() {
      this.setCustomers = this.selectedCustomers;
    },
  },
  computed: {
    setCustomers: {
      get() {
        return this.customers;
      },
      set(value) {
        this.$emit("update:customers", value);
      },
    },
    setAddRecipientStatus: {
      get() {
        return this.addRecipient;
      },
      set(value) {
        this.$emit("update:addRecipient", value);
      },
    },
  },
  methods: {
    addMorePeople() {
      if (this.customer != null) {
        this.selectedCustomers = [this.customer.id];
      }
      this.setAddRecipientStatus = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.faint-circle {
  border: 1px solid var(--eden-grey-senary);
  padding: 2px 2px;
  background-color: var(--eden-grey-senary);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  font-size: 8px;
}
.el-input > .el-input__inner {
  border: 0px !important;
  outline: 0px !important;
}
</style>
