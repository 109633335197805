import axios from "axios";

export default {
  get(start_date) {
    return axios.get(`crm/gardener/bulk/message?start_date=${start_date}`);
  },
  sendMessage(payload) {
    return axios.post("crm/gardener/bulk/message", payload);
  },
};
