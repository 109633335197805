<template>
  <div>
    <el-dialog
      :title="
        customer == null || multiplePeople
          ? 'Send Bulk Message To Customers'
          : 'Send Message to ' + formatName(customer.name)
      "
      :visible.sync="setShow"
      @close="closeEvent"
      width="40%"
      append-to-body
    >
      <el-row type="flex">
        <el-col :span="24">
          <single-recipient-list
            v-if="customer != null"
            :customer="customer"
            :allCustomers="allCustomers"
            :customers.sync="selectedCustomers"
            :addRecipient.sync="multiplePeople"
          />
          <bulk-recipient-list
            v-else
            :customers.sync="setCustomers"
            :addRecipient.sync="multiplePeopleBulk"
          />
        </el-col>
      </el-row>
      <span class="line"></span>
      <el-row>
        <el-col :span="24">
          <p>
            <el-input
              ref="message"
              :rows="10"
              type="textarea"
              placeholder="Start typing your message here..."
              v-model="message"
            />
          </p>
        </el-col>
      </el-row>
      <span class="line"></span>
      <span class="dialog-footer">
        <span class="text-left font-sm"
          >Tip: Type
          <span v-for="(name, index) in names" :key="index">
            <el-tag
              type="success"
              class="name-tip text-cursor"
              @click="fixNameTag(name)"
            >
              @{{ name }}
            </el-tag>
          </span>
          where you want the customer’s name to appear in the message when you
          send it.</span
        >
        <el-button
          type="primary"
          :loading="loading"
          @click="send"
          :disabled="
            !message || (customer != null && !selectedCustomers.length)
          "
          >Send Message</el-button
        >
      </span>
    </el-dialog>
    <eden-confirm-dialog
      title="Send this bulk message?"
      button-text="Yes, send message"
      cancel-text="No, go back to draft"
      button-type="success"
      :button-status.sync="loading"
      :show.sync="showConfirm"
      @confirm="sendMessage"
      @close="backToDraft"
    >
      <p>Are you sure you want to send this message?</p>
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="View logs?"
      button-text="Yes, view logs"
      cancel-text="No, stay in current view"
      button-type="success"
      :button-status.sync="loading"
      :show.sync="showViewLogs"
      @confirm="routeToLogs"
      @close="showViewLogs = false"
    >
      <p>Do you want to view logs?</p>
    </eden-confirm-dialog>
    <unsent-messages
      :show.sync="showUnsent"
      :users="unsentUsers"
      @success="confirmChatLinked"
    />
  </div>
</template>

<script>
import SingleRecipientList from "@/components/Gardeners/Messaging/SingleRecipientList";
import BulkRecipientList from "@/components/Gardeners/Messaging/BulkRecipientList";
import UnsentMessages from "@/components/Gardeners/Messaging/UnsentMessages";
import messaging from "@/requests/gardeners/messaging";
export default {
  name: "SendMessageTemplate",
  components: { SingleRecipientList, BulkRecipientList, UnsentMessages },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    resentMessage: {
      type: String,
      default: "",
    },
    customer: {
      type: Object,
      default() {
        return null;
      },
    },
    customers: {
      type: Array,
      default() {
        return [];
      },
    },
    allCustomers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      message: "",
      showConfirm: false,
      selectedCustomers: [],
      multiplePeople: false,
      multiplePeopleBulk: false,
      messageSent: false,
      showUnsent: false,
      showViewLogs: false,
      unsentUsers: [],
      batchID: "",
      messageResponse: {},
      names: ["fullname", "firstname", "lastname"],
    };
  },
  watch: {
    show() {
      if (this.resentMessage) {
        this.message = this.resentMessage;
      }
    },
  },
  mounted() {
    console.log(this.customers);
    console.log(this.customer);
    console.log(this.resentMessage);
    if (this.show && this.resentMessage) {
      this.message = this.resentMessage;
    }
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    setCustomers: {
      get() {
        return this.customers;
      },
      set(value) {
        this.$emit("update:customers", value);
      },
    },
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.message = "";
      this.multiplePeople = false;
      this.multiplePeopleBulk = false;
    },
    routeToLogs() {
      this.$router.push({
        name: "communications.bulk-messages.index",
        params: { id: this.batchID },
      });
    },
    send() {
      console.log(this.showConfirm);
      this.draftMessage = this.message;
      this.setShow = false;
      this.showConfirm = true;
      this.messageSent = false;
      console.log(this.showConfirm);
    },
    backToDraft() {
      if (!this.messageSent) {
        this.multiplePeople = true;
        this.showConfirm = false;
        this.message = this.draftMessage;
        this.setShow = true;
      }
    },
    confirmChatLinked() {
      this.$emit("success", this.messageResponse);
      this.closeEvent();
      console.log(this.resentMessage);
      if (this.resentMessage === "") {
        this.showViewLogs = true;
      }
    },
    sendMessage() {
      this.showUnsent = false;
      let receiver_ids = [];
      if (this.selectedCustomers.length) {
        receiver_ids = this.selectedCustomers.map((customer) => {
          return customer.toString();
        });
      } else {
        receiver_ids = this.setCustomers.map((customer) => {
          return customer.id.toString();
        });
      }
      let payload = {
        sender_id: this.userId,
        receiver_ids,
        message: this.draftMessage,
      };
      messaging
        .sendMessage(payload)
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            this.messageSent = true;
            this.showConfirm = false;
            this.messageResponse = response.data.data.stored_messages;
            this.batchID = response.data.data.stored_messages.id;
            let unsent = response.data.data.unproccessed_messages_user_ids;
            if (unsent.length) {
              console.log(this.showUnsent, "unsent");
              this.showUnsent = true;
              this.unsentUsers = unsent;
            } else {
              this.$message.success(response.data.message);
              this.$emit("success", this.messageResponse);
              console.log(this.resentMessage);
              if (this.resentMessage === "") {
                this.showViewLogs = true;
              }
            }
          } else {
            this.$message.error(response.data.message);
            this.backToDraft();
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.backToDraft();
        });
      console.log(this.draftMessage);
    },
    fixNameTag(name) {
      let nameTag = ` @${name}`;
      if (this.message[this.message.length - 1] === " ") {
        nameTag = `@${name}`;
      }
      this.message += nameTag;
      this.$refs.message.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.name-tip {
  text-transform: none !important;
  font-size: 12px !important;
  padding: 0px 5px !important;
  height: 20px;
  line-height: 20px;
  /* Green/Septenary */

  background: #e9fbf2 !important;
  border-radius: 12px !important;
}
.el-textarea__inner {
  border-width: 0px !important;
  border: none !important;
  outline: none !important;
}
.line {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--eden-grey-septenary);
  margin-bottom: 16px;
}
.faint-circle {
  border: 1px solid var(--eden-grey-senary);
  padding: 2px 2px;
  background-color: var(--eden-grey-senary);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  font-size: 8px;
}
</style>
