<template>
  <el-dialog
    title="Unsent Messages"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="40%"
    append-to-body
  >
    <el-row type="flex">
      <el-col :span="24">
        <div class="text-bold mb-10">
          Chats for the following users have not been linked, message not sent
        </div>

        <el-tag v-for="(user, index) in users" :key="index" class="mb-10">
          {{ user.name }}
        </el-tag>

        <div class="mt-10 text-primary text-cursor text-right">
          <el-button type="primary" :loading="linking" @click="linkChat"
            >Click to link chat <span><i class="el-icon-link"></i></span
          ></el-button>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";
export default {
  name: "UnsentMessages",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      linking: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      console.log(this.show);
      console.log(this.shouldShow);
      console.log(this.users);
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    linkChat() {
      this.linking = true;
      let emails = this.users.map((user) => {
        return user.email;
      });
      const payload = {
        customer_emails: emails,
      };

      customer
        .chat(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.users);
            this.linking = false;
            this.closeEvent();
          } else {
            this.linking = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.linking = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
